import React, {useEffect} from 'react';

import {activeMenu} from "./Components/HomeComponent";
import MetaTags from "react-meta-tags";

function Infrastructure() {
    useEffect(() => {
         activeMenu('infrastructure');
        setTimeout(function () {
            window.activeNow();
        }, 10000)
        window.initAOS();
    }, [])

    return <>
        <MetaTags>
            <title>Cơ sở vật chất</title>
            <meta name="description" content={`Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi xuyên suốt và luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm tin, làn da khoẻ đẹp là của bạn!`}/>
            <meta name="keywords" content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`} />
            <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
        </MetaTags>
        <section id="blog-page" className="blog-page blog-page-title">
            <div
                className="container-fluid page-header wow fadeIn"
                data-wow-delay="0.1s"
                id={`infrastructure`}
            >
                <div className="container py-5">
                    <h1 className="display-4 mb-4 text-center">Cơ sở vật chất</h1>
                </div>
            </div>
            <div className="visual-header">
                <img src="assets/img/va/visual.png" className="visual-default"/>
                <img src="assets/img/va/visual-hover.png" className="visual-hover"/>
            </div>
        </section>
        <section id="infrastructure" className="infrastructure">
            <div className="container">
                <div className="row justify-content-center infrastructure-list">
                    <div className="infrastructure-iten">
                        <div className="img-infrastructure">
                            <img src="assets/img/va/infrastructure-1.png" className="infrastructure-defult"/>
                            <img src="assets/img/va/infrastructure-2.png" className="infrastructure-hover"/>
                        </div>
                        <div className="name-infrastructure">
                            <h3>IONTOSON 2</h3>
                            <p>Thiết bị chăm sóc da 5 trong 1</p>
                        </div>
                    </div>
                    <div className="infrastructure-iten">
                        <div className="img-infrastructure">
                            <img src="assets/img/va/infrastructure-4.png" className="infrastructure-defult"/>
                            <img src="assets/img/va/infrastructure-3.png" className="infrastructure-hover"/>

                        </div>
                        <div className="name-infrastructure">
                            <h3>Meicet</h3>
                            <p>Thiết bị phân tích da chuyên nghiệp Meicet</p>
                        </div>
                    </div>
                    <div className="infrastructure-iten">
                        <div className="img-infrastructure">
                            <img src="assets/img/va/infrastructure-5.png" className="infrastructure-defult"/>
                            <img src="assets/img/va/infrastructure-6.png" className="infrastructure-hover"/>
                        </div>
                        <div className="name-infrastructure">
                            <h3>Liftera A</h3>
                            <p>Công ty Cổ phần TM&DV Việt Can</p>
                        </div>
                    </div>
                    <div className="infrastructure-iten">
                        <div className="img-infrastructure">
                            <img src="assets/img/va/infrastructure-7.png" className="infrastructure-defult"/>
                            <img src="assets/img/va/infrastructure-8.png" className="infrastructure-hover"/>
                        </div>
                        <div className="name-infrastructure">
                            <h3>Helios III</h3>
                            <p>Máy trị nám, tàn nhang công nghệ cao</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Infrastructure;