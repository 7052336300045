import React, {useEffect, useState} from "react";
import {activeMenu, getConfig} from "./Components/HomeComponent";
import MetaTags from "react-meta-tags";
import imagesContact from '../img/contact.png'

function Contact() {
    const [configSite, setConfigSite] = useState(null);
    useEffect(() => {
        getConfig().then(result => {
            setConfigSite(result.data);
            console.log(result.data);
        })
        activeMenu('contact')
        setTimeout(function () {
            window.activeNow();
            window.initAOS();

        }, 1000)

    }, [])


    if (!configSite) {
        return <div id="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }

    return <>
        <MetaTags>
            <title>Liên hệ</title>
            <meta name="description" content={`CÔNG TY TNHH OCEAN COSMETISC`}/>
            <meta name="keywords"
                  content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`}/>
            <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
        </MetaTags>
        <div className={`padding-desktop`}> </div>
        <section id="contact-page" className="">
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-6 col-md-6">
                        <div className="stats-item text-left w-100 h-100">
                            <h3 className="contact-title">CÔNG TY TNHH OCEAN COSMETISC</h3>
                            <p>Chúng tôi luôn sẵn sàng lắng nghe và đưa ra giải pháp phù hợp nhất
                                cho vấn đề của bạn.</p>
                            <div className="d-flex align-items-center py-3 contact-item">
                                <div className="contact-icon">
                                    <img src="assets/img/va/ft-icon1.png"/>
                                </div>
                                <div className="contact-info mx-lg-3">
                                    <div className={`desktop`}>Địa chỉ</div>
                                    <div className="ft-txt">{configSite.branch}</div>
                                </div>

                            </div>
                            <div className="d-flex align-items-center py-3 contact-item">
                                <div className="contact-icon">
                                    <img src="assets/img/va/ft-icon2.png"/>
                                </div>
                                <div className="contact-info mx-lg-3">
                                    <div className={`desktop`}>Số điện thoại</div>
                                    <div className="ft-txt">{configSite.phone}</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center py-3 contact-item no-border">
                                <div className="contact-icon">
                                    <img src="assets/img/va/ft-icon3.png"/>
                                </div>
                                <div className="contact-info mx-lg-3">
                                    <div className={`desktop`}>Email</div>
                                    <div className="ft-txt">{configSite.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 image-contact">
                        <img src={imagesContact}/>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Contact