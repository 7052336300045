import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import {listArticle} from "./AriticelRequets";
import {activeMenu} from "../Components/HomeComponent";
import BoxSearch from "./BoxSearch";

function News(props) {

    let navigate = useNavigate();
    const params = useParams();
    const queryParameters = new URLSearchParams(window.location.search)

    const [articleItems, setArticleItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastID, setLastID] = useState('');
    const [keySearch, setKeySearch] = useState('');
    const [categoryID, setCategoryID] = useState('');
    const [loadMore, setLoadMore] = useState(false);

    useEffect(() => {
        let search = '';
        let caID = '';
        setLastID('')
        if (queryParameters.get("search")) {
            search = queryParameters.get("search");
        }
        if (queryParameters.get("caid")) {
            caID = queryParameters.get("caid");
        }
        setArticleItems([]);
        setLoadMore(false);
        getArticle(search, caID, lastID, true);

    }, [queryParameters.get("search"), queryParameters.get("caid")])

    useEffect(() => {
        activeMenu('news')
    }, [params.page])

    // useEffect(() => {
    //     console.log(articleItems)
    // }, [articleItems])

    const getArticle = (search, caID, lastID, isLoad) => {
        setKeySearch(search)
        setCategoryID(caID)
        if(isLoad) {
            setLoading(true)
        }

        listArticle(search, caID, lastID).then(result => {
            let listItem = [...articleItems, ...result.data];
            setArticleItems(listItem);
            setLoadMore(false)
            let total = result.data.length;

            if (total >= 9 && result.data[total - 1].id !== lastID) {
                setLoadMore(true);
                setLastID(result.data[total - 1].id);
            }else {
                setLoadMore(false);
            }
        }).finally(() => {
            setLoading(false);

            if(isLoad) {
                window.initAOS();
            }
        })
    }

    if (loading) {
        return  <div id="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }



    return <>
        <MetaTags>
            <title>Tin tức Phong kham da lieu le van anh Phóng khám da liễu vân anh</title>
                <meta name="description" content={`Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi xuyên suốt và luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm tin, làn da khoẻ đẹp là của bạn!`}/>
                <meta name="keywords" content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`} />
                <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
        </MetaTags>
        <section id="blog-page" className="blog-page blog-page-title">
            <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 mb-4 text-center">Tin tức</h1>
                </div>
            </div>
        </section>
        <BoxSearch caId={categoryID} keySearch={keySearch}/>
        <section id="blog-list" className="blog-page">
            <div className="container">
                <h4 className="title-blog visual">Tin tức nổi bật</h4>
                <div className="row justify-content-center">
                    { (articleItems.length)? articleItems.map((item, index) => {

                        return <div
                            className="col-md-4 col-xs-12"
                            key={`blog-key-${item.id}`}
                        >
                            <Link to={`/new/${item.slug}`}   className="blog-item my-3">
                                <img src={item.fullImage} width="100%"/>
                                <div className="blog-item-title-small">{item.categorie.title}</div>
                                <div className="blog-item-title">{item.title}</div>
                                <div className="blog-item-date-small">{item.pushDate} | by Vân Anh Cosmetics</div>
                            </Link>
                        </div>
                    }): <>
                        <div className={`articles`}>
                            <p className={`no-posts`}>Chưa có bải viết nào</p>
                        </div>
                    </>
                    }
                </div>
                {
                    (loadMore)? <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <button
                                className="read-more rounded-pill"
                                onClick={()=> getArticle(keySearch, categoryID, lastID, false)}
                            >
                                <span>Xem thêm</span>
                            </button>
                        </div>
                    </div>: ''
                }

            </div>
        </section>
    </>
}

export default News;