import React, {useEffect, useState} from 'react';
import statsBg from "../../img/va/stats-bg.png";
import mask6 from "../../img/va/Mask6.jpg";
import icon1Sgv from "../../img/va/icon1.svg";
import icon2Sgv from "../../img/va/icon2.svg";
import icon3Sgv from "../../img/va/icon3.svg";
import {getService} from "./ServiceRequets";
import circle from "../../img/circle.png";
import {Link} from "react-router-dom";

function ServiceHome () {
    const [loading, setLoading] = useState(false);
    const [dataService, setDataService] = useState([])
    const [tabActivce, setTabActivce] = useState(0)
    useEffect(() => {
        setLoading(true)
        getService().then(result => {
            setDataService(result.data);
        }).finally(()=>{
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        if (dataService[tabActivce]) {
            console.log(dataService[tabActivce].fullImage)
        }

    }, [tabActivce]);

    return <>
        {(dataService)?  <section id="services" className="stats">
                <img src={statsBg} alt="" data-aos="fade-in"/>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4">
                        <div className="col-lg-7 col-md-7">
                            <div className="stats-item text-left w-100 h-100">
                                <h2>Các dịch vụ <br/>chúng tôi cung cấp</h2>

                                {(dataService[tabActivce])?<img src={dataService[tabActivce].fullImage} className="mobile"/>: '' }
                                <br/>
                                <div className="d-flex text-center">
                                    {
                                        dataService.map((item, index) => {
                                            let classActive = '';
                                            if(tabActivce === index) {
                                                classActive = 'active';
                                            }

                                            return <div
                                                key={`li-${index}`}
                                                 className={`control-item ${classActive}`}
                                                onClick={() => setTabActivce(index)}
                                            >
                                                <div className="icon-ctrl">
                                                    <img src={item.fullIcon} width="100%"/>
                                                </div>
                                                <div className="text">{item.title}</div>
                                            </div>
                                        })}
                                </div>
                                <br/>
                                <Link to={`/service`} className="read-more rounded-pill"><span>Tìm hiểu thêm</span></Link>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 desktop " style={{position: 'relative'}}>
                            {(dataService[tabActivce])?<img src={dataService[tabActivce].fullImage}/>: '' }
                        </div>
                    </div>
                </div>
            </section>:''
        }
    </>

}

export default ServiceHome;