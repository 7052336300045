import React, {useEffect, useState} from 'react';
import MetaTags from 'react-meta-tags';

import visual from '../img/va/visual.png';
import doctor1 from '../img/doctor1.png';
import doctor2 from '../img/doctor2.png';
import doctor3 from '../img/doctor3.png';
import doctor4 from '../img/doctor4.png';
import doctor5 from '../img/doctor5.png';
import aboutLeft from '../img/about-left.png';
import aboutRight from '../img/about-right.png';

import {activeMenu} from "./Components/HomeComponent";
function About() {

    useEffect(() => {
        activeMenu('about');
        setTimeout(function () {
            window.activeNow();
        }, 10000)
        window.initAOS();
    }, [])



    return <>
        <MetaTags>
            <title>Về chúng tôi</title>
            <meta name="description" content={`Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi xuyên suốt và luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm tin, làn da khoẻ đẹp là của bạn!`}/>
            <meta name="keywords" content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`} />
            <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
        </MetaTags>
        <br/>
        <br/>
        <section id="about-page" className="about-page page-child">
            <div className="container">
                <div className="d-flex">
                    <img src={visual} className="about-visual desktop"/>
                        <img src={doctor1} className="about-doctor desktop"/>
                            <div className="about-intro page-child-content">
                                <h4 className="title-about">Về chúng tôi</h4>
                                <p>
                                    Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi
                                    xuyên suốt
                                    và
                                    luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm tin, làn
                                    da khoẻ đẹp
                                    là
                                    của bạn!
                                    <br/>
                                    <br/>
                                    Là hình mẫu lý tưởng cho những người phụ nữ trẻ hiện đại, CEO Lê Vân Anh cho rằng
                                    bên cạnh sự
                                    bản
                                    lĩnh thì sắc đẹp cũng tạo nên khí chất của một người phụ nữ. Nhất là ở thời đại này,
                                    sắc đẹp là
                                    tài
                                    sản, là trí tuệ. Bất cứ người phụ nữ nào coi trọng điều này đều xứng đáng được hưởng
                                    một cuộc
                                    sống
                                    hạnh phúc và viên mãn hơn. Nhưng không vì thế mà tìm kiếm những giá trị xa vời, nhan
                                    sắc được
                                    kiến
                                    tạo từ những điều rất đỗi bình thường. Dành thời gian nghỉ ngơi, cân bằng giữa công
                                    việc và cuộc
                                    sống, có thói quen tốt như tập thể dục, đọc sách để trau dồi kiến thức của bản thân.
                                    Chăm sóc da
                                    thường xuyên, đều đặn chính là chìa khoá nuôi dưỡng nhan sắc của mỗi người phụ nữ.
                                </p>
                            </div>
                </div>
                <div className="row wrapper-image-about">
                    <img src={aboutLeft} className="image-about"/>
                    <img src={aboutRight} className="image-about"/>
                </div>
            </div>
            <div className="intro-layer"></div>
            <div className="container">
                <div className="detail-about p-5 page-child-content">
                    <div className="row py-3">
                        <div className="col-md-6 col-xs-12">
                            <div className="px-3 text-justify">
                                <h4 className="title-about">Trải qua một hành trình dài không ngừng nỗ lực với gần 15
                                    năm hoạt động, Lê Vân Anh
                                    Cosmetic
                                    đã gặt hái được thành công vang dội.</h4>
                                <p>Điều này không chỉ đến từ những sản phẩm được lựa chọn cẩn thận chu đáo mà còn là sự
                                    học
                                    hỏi,
                                    cập nhật không ngừng các kiến thức chuyên môn cũng như tâm huyết của đội ngũ chuyên
                                    gia.
                                    Kết
                                    quả là đã có hàng triệu làn da Việt được cải thiện, đẹp hơn, tự tin hơn để toả sáng.
                                    Bên
                                    cạnh đó, vẫn còn có những làn da gặp phải các vấn đề mà việc sử dụng dược mỹ phẩm
                                    đơn
                                    thuần
                                    chưa thể giải quyết được.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 text-center">
                            <div className="px-5">
                                <img src={doctor2} width="100%" style={{borderRadius: 10}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row py-3 grid-about">

                        <div className="col-md-6 col-xs-12">
                            <div className="d-flex align-items-center px-3">
                                <div>
                                    <img src={doctor3} className="p-3"/>
                                    <img src={doctor4} className="p-3"/>
                                </div>
                                <div>
                                    <img src={doctor5} className="p-3"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="px-5 text-justify">
                                <h4 className="title-about">Bên cạnh trang thiết bị máy móc hiện đại, sự đồng hành xuyên
                                    suốt cùng với đội ngũ BS và
                                    chuyên gia của Phòng khám cam kết đem lại những trải nghiệm tốt nhất đến với các
                                    khách
                                    hàng
                                </h4>
                                <p>
                                    Ngoài kia, hàng ngày báo chí truyền thông đưa tin còn rất nhiều cơ sở đẹp chưa được
                                    cấp
                                    phép, rất nhiều KH “tiền mất tật mang”, đẹp hơn chưa thấy mà còn để lại hậu quả nặng
                                    nề,
                                    ảnh
                                    hưởng nghiêm trọng đến sức khoẻ. Sau biết bao trăn trở, với những nỗ lực không ngừng
                                    nghỉ
                                    nhằm tạo ra điểm đến tin cậy cho tất cả các khách hàng mong muốn cải thiện làn da
                                    một
                                    cách
                                    hiệu quả nhất ,CEO Lê Vân Anh và đội ngũ ấp ủ dự án tiếp theo - Phòng Khám Da Liễu
                                    Lê
                                    Vân
                                    Anh.
                                    <br/>
                                    <br/>
                                    Phòng Khám Da Liễu Lê Vân Anh là cơ sở được sở y tế HN cấp phép hoạt động, cung cấp
                                    dịch
                                    vụ
                                    và giải pháp làm đẹp an toàn - chuẩn y khoa. Đến với Phòng Khám Da Liễu Lê Vân Anh,
                                    hai
                                    yếu
                                    tố tôn chỉ hoạt động luôn song hành là “an toàn và chất lượng”. Tất cả các máy móc
                                    thiết
                                    bị
                                    lựa chọn đều là những công nghệ hàng đầu Âu Mỹ và Hàn Quốc, đều được tổ chức uy tín
                                    nhất
                                    thế
                                    giới FDA Approved. Bên cạnh trang thiết bị máy móc hiện đại, sự đồng hành xuyên suốt
                                    cùng
                                    với đội ngũ BS và chuyên gia của Phòng khám cam kết đem lại những trải nghiệm tốt
                                    nhất
                                    đến
                                    với các KH.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default About;