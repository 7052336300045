import React, {useEffect, useState} from 'react';
import logo from '../img/logo.png'
import ftIcon1 from '../img/va/ft-icon1.png'
import ftIcon2 from '../img/va/ft-icon2.png'
import ftIcon3 from '../img/va/ft-icon3.png'
import ftIcon4 from '../img/va/ft-icon4.png'
import {Link} from "react-router-dom";


function Footer(props) {

    if (!props.config) {
        return <></>
    }

    return <>
        <footer id="footer" className="footer">
            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-12 col-md-12 text-center footer-about">
                        <a href="index.html" className="logo d-flex align-items-center justify-content-center">
                            <span><img src={logo}/></span>
                        </a>
                        <h4>CÔNG TY TNHH OCEAN COSMETICS</h4>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <img src={ftIcon1}/>
                            <div className="ft-txt">{props.config.branch}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <img src={ftIcon2}/>
                            <div className="ft-txt">{props.config.phone}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <img src={ftIcon3}/>
                            <div className="ft-txt">{props.config.email}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <img src={ftIcon4}/>
                            <div className="ft-txt">9h00 - 18h00 tất cả các ngày trong tuần</div>
                    </div>
                </div>
            </div>
            <div className="copyright">

            </div>
        </footer>
    </>
}

export default Footer