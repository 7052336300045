import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";

import {useFormik} from "formik";
import Select from "react-select";
import {activeMenu} from "../Components/HomeComponent";
import {listCategoris} from "./AriticelRequets";

function BoxSearch(props) {
    let navigate = useNavigate();
    const [selectItem, setSelectItem] = useState('');
    const [selectID, setSelectID] = useState('');
    const [seleteOptions, setSeleteOptions] = useState([]);
    const params = useParams();
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            search: props.keySearch? props.keySearch: '',
        },
        onSubmit: values => {
            navigate(`/news?search=${values.search}&caid=${selectID}`);
        },
    });

    useEffect(() => {
        activeMenu('news');
        listCategoris().then(result =>{
            setSeleteOptions(result.data);
            for (let i=0; i < result.data.length; i ++){
                if(result.data[i].value == props.caId) {
                    setSelectItem(result.data[i]);
                    setSelectID(result.data[i].value);
                    break;
                }
            }
        });
    }, [params.page]);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: "none",
            outline: 0,
            border: 'none'
            // You can also use state.isFocused to conditionally style based on the focus state
        })
    };

    const changeSelectItem = (event) => {
       let item = seleteOptions.find(x => x.value === event.value);
        setSelectItem(item);
        setSelectID(event.value);
    }

    const removeSelectItem = () => {
        setSelectID('');
        setSelectItem('');
    }

    return <>
        <div id="blog-search">
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
            >
                <div className="col-search-left">
                    <i className="bi bi-search"></i>
                    <input
                        type="text"
                        className="form-control py-3"
                        id="search"
                        placeholder="Tìm kiếm tin tức"
                        style={{boxShadow: "none"}}
                        autoComplete='off'
                        value={formik.values.search || ''}
                        name='search'
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="col-search-right">
                    <i className="bi bi-list-columns-reverse"></i>
                    <Select
                        defaultValue={selectItem}
                        onChange={changeSelectItem}
                        options={seleteOptions}
                        value={selectItem}
                        styles={customStyles}
                        id={'select-category'}
                        placeholder='Chủ đề'
                        // menuIsOpen={true}
                    />
                    {(selectItem)? <i className="bi bi-x" onClick={()=> removeSelectItem()}></i>: ''}
                    <button
                        className="btn btn-primary bg-primary py-3 px-5"
                        type="submit"
                        style={{boxShadow: "none"}}
                    >
                        Tìm kiếm
                    </button>
                </div>
            </form>
        </div>
    </>
}

export default BoxSearch;