import React from "react";
import {Route, Routes, BrowserRouter} from 'react-router-dom';

import './css/bootstrap.min.css'
import './css/animate.min.css'
// import './css/style.css'
import './css/main.css'
import Layout from "./Layout/index";
import Home from "./Features/Home";
import About from "./Features/About";
import Infrastructure from "./Features/Infrastructure";
import Services from "./Features/Services";
import News from "./Features/News/List";
import DetailArticle from "./Features/News/DetailArticle";

import Contact from "./Features/Contact";
function App() {

  return <BrowserRouter>
    <Layout>
      <Routes>
        <Route exact={true} path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/infrastructure" element={<Infrastructure/>}/>
        <Route path="/service" element={<Services/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/new/:slug" element={<DetailArticle/>}/>
        {/*<Route path="/careers/:page?" element={<Careers/>}/>*/}
        {/*<Route path="/career/:slug?" element={<CareerDetail/>}/>*/}
        <Route path="/contact" element={<Contact/>}/>

      </Routes>
    </Layout>
  </BrowserRouter>

}

export default App;
