import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";

import {detailArticle} from "./AriticelRequets";
import {activeMenu} from "../Components/HomeComponent";
import BoxSearch from "./BoxSearch";
import MetaTags from "react-meta-tags";

function DetailArticle(props) {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [otherArticles, setOtherArticles] = useState([]);
    const [titleOther, setTitleOther] = useState('');

    useEffect(() => {
        setLoading(true)
        detailArticle(params.slug).then(result => {
            setDetail(result.data.detail);

            setOtherArticles(result.data.otherArticles);
            setTitleOther(result.data.title);
        }).finally(() => {
            window.initAOS();
            setLoading(false)
        })
        activeMenu('news')
    }, [params.slug]);

    useEffect(() => {
        console.log(detail)
    }, [detail])

    if (loading) {
        return <div id="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }

    return <>
        <section id="blog-page" className="blog-page blog-page-title">
            <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <h1 className="display-4 mb-4 text-center">Tin tức</h1>
                </div>
            </div>
        </section>
        <BoxSearch/>
        <section id="blog-list" className="blog-page">
            <div className="container">
                <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                    {
                        (detail) ?
                            <>
                                <MetaTags>
                                    <title>{detail.title}</title>
                                    <meta name="description" content={detail.descriptions}/>
                                    <meta name="keywords"
                                          content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`}/>
                                    <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
                                </MetaTags>
                                <div className="col-md-12 col-xs-12">
                                    <div className="blog-item my-3">
                                        <div className="blog-item-title-small">{detail.categorie.title}</div>
                                        <div className="blog-item-detail-title">{detail.title}</div>
                                        <div className="blog-item-date-small">{detail.pushDate} | by Vân Anh Cosmetics</div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: detail.content}}></div>
                                </div>
                            </>
                            : <>
                                <div className={`articles`}>
                                    <p className={`no-posts`}>Bài viết không tồn tại</p>
                                </div>
                            </>
                    }
                </div>
                <br/>
                <h4 className="title-blog visual">Bài viết liên quan</h4>
                <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                    {
                        (otherArticles.length) ? otherArticles.map((item, index) => {
                            return <div
                                className="col-md-4 col-xs-12"
                                key={`blog-key-${item.id}`}
                            >
                                <Link to={`/new/${item.slug}`} className="blog-item my-3">
                                    <img src={item.fullImage} width="100%"/>
                                    <div className="blog-item-title-small">{item.categorie.title}</div>
                                    <div className="blog-item-title">{item.title}</div>
                                    <div className="blog-item-date-small">{item.pushDate} | by Vân Anh Cosmetics</div>
                                </Link>
                            </div>
                        }) : <></>
                    }
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <Link to={`/news`} className="read-more rounded-pill"><span>Xem thêm</span></Link>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default DetailArticle