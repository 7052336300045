export default {
    USER_TOKEN: 'X-Auth-Token',
    language: 'language',
    USER: 'User',
    THEME_COLOR: 'Theme-Color',
    LIMIT: 20,
    debounceTimeout: 200,
    homeStorage: 'home-storage',
    menuStorage: 'menu-storage',
    configStorage: 'config-storage',
    BASE_URL: "https://api.phongkhamdalieulevananh.com/api/product/"
    //BASE_URL: "http://flowercorner.test/api/product/"
}
