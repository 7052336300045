import React, {useEffect, useState} from 'react';

import circle from '../img/circle.png'
import {getService} from "./Components/ServiceRequets";
import {activeMenu} from "./Components/HomeComponent";
import Select from "react-select";
import flagVn from "../images/flag-vn.png";
import MetaTags from "react-meta-tags";
function Services(props) {
    const [loading, setLoading] = useState(false);
    const [dataService, setDataService] = useState([])
    const [tabActivce, setTabActivce] = useState(0)
    const [selectOption, setSelectOption] = useState([])
    useEffect(() => {
        setLoading(true)
        getService().then(result => {
            setDataService(result.data);
            let option = result.data.map((item, index) => {
                return { value: index, label: <div className={`select-service-item`}><img src={circle}/> <h4>{item.title}</h4> </div>}
            })
            setSelectOption(option)

        }).finally(()=>{
            setLoading(false)
        })
        activeMenu('service')
    }, [])
    useEffect(() => {
        if (!dataService) {
            setTimeout(function () {
                window.activeNow();

            }, 1000);
            window.initAOS();
        }
    }, [dataService]);



    const customStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: "none",
            outline: 0
            // You can also use state.isFocused to conditionally style based on the focus state
        })
    };

    if (loading) {
        return  <div id="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }

    return <>
        <MetaTags>
            <title>Dịch vụ</title>
            <meta name="description" content={`Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi xuyên suốt và luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm tin, làn da khoẻ đẹp là của bạn!`}/>
            <meta name="keywords" content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`} />
            <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
        </MetaTags>
        <div className={`padding-desktop`}>

        </div>
        <section id="service-page" className="">
            <div className="container service-page-slider">
                <div className='service-content-left desktop'>
                    <h2>Các dịch vụ <br/>
                        chúng tôi cung cấp</h2>
                    <br/>
                    <ul>
                        {
                            dataService.map((item, index) => {
                            let classActive = '';
                            if(tabActivce === index) {
                                classActive = 'active';
                            }

                            return <li
                                key={`li-${index}`}
                                className={`${classActive}`}
                                onClick={() => setTabActivce(index)}
                            >
                                <div className="image-icon">
                                    <img src={circle} className="icon-service-title"/>
                                </div>
                                <h4>{item.title}</h4>
                            </li>
                        })}
                    </ul>

                </div>
                <div className="service-content-right">
                    <div id={`select-service`}>
                        <Select
                            defaultValue={selectOption[tabActivce]}
                            onChange={(event) => {
                                setTabActivce(event.value);
                            }}
                            options={selectOption}
                            value={selectOption[tabActivce]}
                            styles={customStyles}
                            // menuIsOpen={false}
                        />
                    </div>
                    <div className="service-content">
                        {(dataService[tabActivce])? <p>{dataService[tabActivce].descriptions}</p>: ''}

                    </div>
                    <div className="service-images">
                        {(dataService[tabActivce])? <img src={dataService[tabActivce].fullImage} className="img-responsive" width="100%"/>: ''}
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Services