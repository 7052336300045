import React, {useEffect, useState} from 'react';
import contactImg from '../img/bg-contact.jpg'
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from 'react-toastify';
import {contact} from "../Features/Careers/CareersRequet";

function FormContact(props) {
    const [serverError, setServerError] = useState('')
    const [loading, setLoading] = useState('')
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: false,
        initialValues: {
            name: '',
            message: '',
            phone: '',
            email: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('This field is required'),
            message: Yup.string().required('This field is required'),
            email: Yup.string().email("Invalid Mail format")
                .required("This field is required"),
            phone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required('This field is required')
        }),
        onSubmit: values => {
            setLoading(true)
            setServerError(null)
            contact(values).then(result => {
                toast.success(result.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                formik.resetForm();

            }).catch(error => {

                if (error.response.status !== 400) {
                    return setServerError(error.response.data.message)
                }

                let errorMessage = error.response.data.message;
                if (typeof errorMessage === 'string') {
                    setServerError(errorMessage);
                    return false;
                }

                for (const item in errorMessage) {
                    formik.setFieldError(item, errorMessage[item][0]);
                }

            }).finally(() => {
                setLoading(false)
            })
        },
    })

    return <>
        <section
            id="call-to-action"
            className="call-to-action calendar-contact"
            style={{backgroundImage:`url("${contactImg}")` }}
            rel={props.refItem}
        >
            {/*<img src={contactImg} alt=""/>*/}
            <div className="container">

                <div className="row justify-content-end" data-aos="zoom-in" data-aos-delay="100">

                    <div className="col-md-6 col-xs-12">
                        <h3 className={'title-call-action'}>Đặt lịch hẹn chăm sóc da</h3>
                        <form
                            onSubmit={formik.handleSubmit}
                            autoComplete="off"
                            className="php-email-form aos-init aos-animate" data-aos="fade-up"
                            data-aos-delay="200"
                        >
                            <div className="row gy-4">
                                <div className={`col-md-12 ${(formik.errors.name)?'field-error': ''}`} >
                                    <input
                                        type="text"
                                        name="name"
                                        className={`form-control`}
                                        placeholder="Họ tên*"
                                        value={formik.values.name || ''}
                                        onChange={formik.handleChange}
                                    />
                                    {<span className="error">{formik.errors.name}</span>}
                                </div>

                                <div className={`col-md-12 ${(formik.errors.phone)?'field-error': ''}`} >
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        name="phone"
                                        placeholder="Số điện thoại*"
                                        value={formik.values.phone || ''}
                                        onChange={formik.handleChange}
                                    />
                                    {<span className="error">{formik.errors.phone}</span>}
                                </div>

                                <div className={`col-md-12 ${(formik.errors.email)?'field-error': ''}`} >
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        name="email"
                                        placeholder="Email*"
                                        value={formik.values.email || ''}
                                        onChange={formik.handleChange}
                                    />
                                    {<span className="error">{formik.errors.email}</span>}
                                </div>

                                <div className={`col-md-12 ${(formik.errors.message)?'field-error': ''}`} >
                                    <textarea
                                        className={`form-control`}
                                        name="message" rows="4"
                                        placeholder="Nội dung*"
                                        value={formik.values.message || ''}
                                        onChange={formik.handleChange}
                                    >
                                    </textarea>
                                    {<span className="error">{formik.errors.message}</span>}
                                </div>
                                {
                                    (serverError)? <div className="col-md-12 text-center">
                                        <span className="error">{serverError}</span>
                                    </div>:''
                                }

                                <div className="col-md-12 text-center">

                                    <button
                                        className="read-more rounded-pill"
                                        type="submit"
                                        style={{boxShadow: "none"}}
                                    >
                                        <span>Đặt lịch</span>
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    </>
}

export default FormContact