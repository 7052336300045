import React, {useEffect, useState, useRef} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {getConfig} from "../Features/Components/HomeComponent";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormContact from "./FormContact";
import PopupComponent from "../Features/Components/PopupComponent";

function Layout(props) {
    const [menus, setMenus] = useState(false);
    const [config, setConfig] = useState(null);

    useEffect(() => {
        getConfig().then(result => {
            setMenus(true);
            setConfig(result.data);
        }).catch(error => {
        })
    }, [])


    return <>
        <Header menus={menus}/>
        <main id="main">
            {props.children}
            <FormContact/>
        </main>

        <Footer config={config}/>
        <ToastContainer/>
        <PopupComponent config={config}/>
    </>
}

export default Layout;