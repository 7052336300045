import Axios from "../../Lib/Axios";
export const listArticle = (search, caID, lastID) => {
    return Axios.get(`news?search=${search}&caId=${caID}&lastId=${lastID}`);
}

export const detailArticle = (slug) => {
    return Axios.get(`news/detail/${slug}`);
}

export const listCategoris = () => {
    return Axios.get(`categorise`);
}

