import React, {useEffect, useState} from 'react';


function PopupComponent (props) {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(()=>{
        let checkLocalStorage = sessionStorage.getItem('showPopup');
        if (props.config && props.config.popup && checkLocalStorage !== "true") {
             setShowPopup(true);
             sessionStorage.setItem('showPopup', true)
        }
    },[props.config]);

    const closedPopup = () => {
        setShowPopup(false);
    }

    if(!showPopup) {
        return <></>
    }

    return <div className={`wrapper-popup`}>
            <div className={`wrapper-popup_content`}>
                <i className="bi bi-x-lg" onClick={() => closedPopup()}></i>
                <a href={props.config.popup.link} target={`_blank`} onClick={() => closedPopup()}>
                    <img src={props.config.popup.image}/>
                </a>
            </div>
    </div>
}

export default PopupComponent