import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import logo from '../img/logo.png'
function Header(props) {

    const { pathname } = useLocation();

    useEffect(() => {
        activeMenu(props.menus);
    }, [props.menus]);
    useEffect(() => {
        window.scrollTo(0,0);
        removoToggle();

    }, [pathname]);

    const activeMenu = (menus) => {
        if (!menus) {
            return false;
        }

        setTimeout(function () {
            window.onscroll = function () {
                let navbarHead = document.getElementById("body-root");
                if (document.documentElement.scrollTop > 50) {
                    navbarHead.classList.add('scrolled');
                    navbarHead.style.top = 0;
                } else {
                    navbarHead.classList.remove('scrolled');
                    navbarHead.style.top = -100;
                }
            };
        }, 100);
    }

    const navToggle = () => {
        let navbarHead = document.getElementById("body-root");
        if (navbarHead.classList.contains('mobile-nav-active')) {
            navbarHead.classList.remove('mobile-nav-active');
        } else {
            navbarHead.classList.add('mobile-nav-active');
        }
    }

    const removoToggle = () => {
        let navbarHead = document.getElementById("body-root");
        if (navbarHead.classList.contains('mobile-nav-active')) {
            navbarHead.classList.remove('mobile-nav-active');
        }
    }

    const handleClickScrollContact = () => {
        removoToggle()
        const element = document.getElementById("call-to-action");
        element.scrollIntoView();
    };

    if (!props.menus) {
        return  <div id="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }
    return <>
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="container d-flex align-items-center justify-content-between">
                <Link to={`/`}className="logo d-flex align-items-center me-auto me-xl-0">
                    <img src={logo} alt=""/>
                        <span>.</span>
                </Link>

                <nav id="navmenu" className="navmenu">
                    <ul>
                        <li>
                            <Link to={`/`} id='home' className="active logo d-flex align-items-center me-auto me-xl-0">
                                <img src={logo} alt="" className="menu-mobile"/>
                                    <div className="menu-desktop">Trang chủ</div>
                            </Link>
                        </li>
                        <li><Link to={`/about`} id='about'>Về chúng tôi</Link></li>
                        <li><Link to={`/service`} id='service'>Dịch vụ</Link></li>
                        <li><Link to={`/infrastructure`} id='infrastructure'>Cơ sở vật chất</Link></li>
                        <li><Link to={`/news`} id='news'>Tin tức</Link></li>
                        <li><Link to={`/contact`} id={`contact`}>Liên hệ</Link></li>
                        <li className="menu-mobile">
                            <div className="btn-getstarted-menu">
                                <button
                                    className="btn-getstarted btn-outline-light rounded-pill"
                                    onClick={()=>handleClickScrollContact()}
                                >
                                    Đặt hẹn
                                </button>
                            </div>
                        </li>
                    </ul>
                    <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={() => navToggle()}></i>
                    <i className="mobile-nav-toggle d-xl-none bi bi-x" onClick={() => navToggle()}></i>
                </nav>
                <button
                    className="btn-getstarted btn-outline-light rounded-pill menu-desktop"
                    onClick={()=>handleClickScrollContact()}
                >
                    Đặt hẹn
                </button>

            </div>
        </header>
    </>
}

export default Header

