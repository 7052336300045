import React, {useEffect, useState} from 'react';
import {getConfig, activeMenu} from "./Components/HomeComponent";
//import banner from '../img/va/banner.png'
import banner from '../img/banner.png'
import bs1 from '../img/bs1.png'
import bs2 from '../img/bs2.png'
import bs3 from '../img/bs3.png'
import mask1 from '../img/mask1.png'
import mask2 from '../img/mask2.png'
import mask3 from '../img/mask3.jpg'
import mask4 from '../img/mask4.png'
import mask5 from '../img/mask5.png'
import frameImg from '../img/va/Frame.png'
import ServiceHome from "./Components/ServiceHome";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";

function Home() {

    const [configSite, setConfigSite] = useState(null);
    useEffect(() => {
        getConfig().then(result => {
            setConfigSite(result.data);
        })

        activeMenu('home')
        setTimeout(function () {
            window.activeNow();
        }, 10000)
    }, [])

    useEffect(() => {
        if (configSite) {
            setTimeout(function () {
                window.activeNow();
            }, 1000);
            window.initAOS();
        }
    }, [configSite])

    if (!configSite) {
        return  <div id="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        return false;
    }

    return <>
        <MetaTags>
            <title>phong kham da lieu le van anh Phóng khám da liễu vân anh</title>
            <meta name="description" content={`Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi xuyên suốt và luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm tin, làn da khoẻ đẹp là của bạn!`}/>
            <meta name="keywords" content={`Renew perfect skin, Phục hồi da, Cân chỉnh gương mặt, Trẻ hóa da, Điều trị nám và sắc tố`} />
            <meta property="site_name" content={`https://phongkhamdalieulevananh.com/about`}/>
        </MetaTags>
        <section id="hero" className="hero">
            <img src={banner} alt="" data-aos="fade-in"/>
        </section>
        <section id="about" className="about">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row align-items-xl-center gy-5">
                    <div className="col-xl-6">
                        <div className="row gy-4 icon-boxes">

                            <div className="col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box">
                                    <img src={mask1} width="100%"/>
                                </div>
                            </div>
                            <div className="col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box">
                                    <img src={mask2} width="100%"/>
                                </div>
                            </div>
                            <div className="col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box">
                                    <img src={mask3} width="100%"/>
                                </div>
                            </div>
                            <div className="col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box">
                                    <img src={mask4} width="100%"/>
                                </div>
                            </div>
                            <div className="col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box">
                                    <img src={mask5} width="100%"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 content">
                        <h2>Câu chuyện thương hiệu</h2>
                        <p>Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo dõi xuyên
                            suốt
                            và luôn đồng hành cùng bạn trong quá trình điều trị.</p>
                        <br/>
                        <p><i>Khi trao chúng tôi niềm tin, làn da khoẻ đẹp là của bạn !</i></p>
                        <br/>
                        <a href="#" className="read-more rounded-pill"><span>Tìm hiểu thêm</span></a>
                    </div>

                </div>
                <div className="row align-items-xl-center gy-5 py-5 team-of-experts">
                    <div className="col-xl-6 content">
                        <h2>Đội ngũ chuyên gia</h2>
                        <br/>
                        <div className="wp-read-more">
                            <a href="#" className="read-more rounded-pill">
                                <span>Xem thêm</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="row gy-4 icon-boxes">
                            <div className="col-xl-4 content">
                                <img src={bs1} width="100%" className={`about-home-img`}/>
                            </div>
                            <div className="col-xl-4 content">
                                <img src={bs2} width="100%"  className={`about-home-img`}/>
                            </div>
                            <div className="col-xl-4 content">
                                <img src={bs3} width="100%"  className={`about-home-img`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ServiceHome/>
        <section id="features" className="features">
            <div className="bg-features">
                <img src={frameImg} className="img-mobile"/>
                <div className="container">
                    <div className="row gy-4 align-items-center features-item">
                        <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            <h3 className="mb-3 desktop">Về chúng tôi</h3>
                            <div className="box-features p-4">
                                <h3 className="mb-3 mobile">Về chúng tôi</h3>
                                <p>
                                    Đội ngũ chuyên gia dày dặn kinh nghiệm của Phòng Khám Da Liễu Lê Vân Anh sẽ theo
                                    dõi
                                    xuyên suốt
                                    và luôn đồng hành cùng bạn trong quá trình điều trị. Khi trao chúng tôi niềm
                                    tin, làn da
                                    khoẻ
                                    đẹp là của bạn!
                                </p>
                                <br/>
                                <p className="desktop">
                                    Là hình mẫu lý tưởng cho những người phụ nữ trẻ hiện đại, CEO Lê Vân Anh cho
                                    rằng bên
                                    cạnh sự
                                    bản lĩnh thì sắc đẹp cũng tạo nên khí chất của một người phụ nữ. Nhất là ở
                                    thời đại này,
                                    sắc đẹp
                                    là tài sản, là trí tuệ. Bất cứ người phụ nữ nào coi trọng điều này đều xứng
                                    đáng được
                                    hưởng một
                                    cuộc sống hạnh phúc và viên mãn hơn. Nhưng không vì thế mà tìm kiếm những
                                    giá trị xa
                                    vời, nhan
                                    sắc được kiến tạo từ những điều rất đỗi bình thường. Dành thời gian nghỉ
                                    ngơi, cân bằng
                                    giữa
                                    công việc và cuộc sống, có thói quen tốt như tập thể dục, đọc sách để trau
                                    dồi kiến thức
                                    của bản
                                    thân. Chăm sóc da thường xuyên, đều đặn chính là chìa khoá nuôi dưỡng nhan
                                    sắc của mỗi
                                    người phụ
                                    nữ.
                                </p>
                                <br/>
                                <Link to={`/about`} className="read-more rounded-pill"><span>Tìm hiểu thêm</span></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out"
                             data-aos-delay="100">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Home;